import React, { useState } from 'react';
import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import Layout from 'layout';
import Link, { StyledLink } from 'components/ui/link';
import Code from 'components/help/codeSnippet';
import badgeLight from 'img/badges/light.svg';
import badgeDark from 'img/badges/dark.svg';

const Section = styled.section`
  margin-top: 5rem;
  & ol {
    padding-inline-start: 20px;
  }
  ${media.desktopWide} {
    margin-top: 3rem;
  }
  ${media.mobile} {
    margin-top: 1rem;
    ${StyledLink} {
      font-size: 18px;
    }
  }
`;

const Container = styled.div`
  ${media.desktopWide} {
    margin-bottom: 3rem;
  }
  ${media.mobile} {
    margin-bottom: 2rem;
    & h1.line {
      & span {
        &::after {
          display: none;
        }
      }
    }
  }
`;

const SubTitle = styled.p`
  font-size: 22px;
  line-height: 34px;
  margin-top: 32px;
  ${media.mobile} {
    margin-top: 24px;
    font-size: 20px;
    line-height: 30px;
  }
`;

const CustomButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 18px;
  line-height: normal;
  color: #000;
  opacity: 0.6;
  font-weight: 400;
  &.active {
    font-weight: 800;
    opacity: 1;
    position: relative;
    &::after {
      content: '';
      width: 90%;
      height: 2px;
      background-color: #0a4ed6;
      position: absolute;
      bottom: -4px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

const Buttons = styled.div`
  margin-bottom: 16px;
  margin-top: 40px;
  ${media.mobile} {
    margin-top: 24px;
  }

  ${CustomButton}:first-child {
    margin-right: 40px;
    ${media.mobile} {
      margin-right: 16px;
    }
    @media (max-width: 340px) {
      margin-right: 0;
    }
  }
`;

const Preview = styled.div`
  display: flex;
  margin: 32px 0 48px;
  align-items: center;
  ${media.mobile} {
    margin: 24px 0 32px;
  }
  & p {
    margin: 0 16px 0 0;
  }
  & img {
    max-width: 130px;
  }
`;

const Badge = () => {
  const [currentbadge, setCurrentbadge] = useState('dark');
  return (
    <Layout
      canonical="/badge/"
      metaTitle="Add a Badge to Your Website"
      metaDescription="Add a LiveSession badge to your website and get 5,000 sessions for free."
    >
      <div className="container">
        <Section className="row">
          <Container className="col-xl-6 col-md-12">
            <h1 className="line">
              Record and analyze <span>+5,000 sessions for free.</span>
            </h1>
            <SubTitle>
              Just add our badge to your website and we&apos;ll give you extra sessions.
            </SubTitle>
          </Container>
          <div className="col-xl-6 col-md-12">
            <ol>
              <li>
                <p>Add this code to the footer on your website:</p>
                <Buttons>
                  <CustomButton
                    type="button"
                    className={currentbadge === 'dark' && 'active'}
                    onClick={() => setCurrentbadge('dark')}
                  >
                    Dark
                  </CustomButton>
                  <CustomButton
                    type="button"
                    className={currentbadge === 'light' && 'active'}
                    onClick={() => setCurrentbadge('light')}
                  >
                    Light
                  </CustomButton>
                </Buttons>
                <Code copy lang="html" currentValue={currentbadge} style={{ marginTop: 0 }}>
                  {`<a href="https://livesession.io/?utm_source=badge">
  <img style="width:108px;height:40px" 
  src="https://static.livesession.io/badge_${currentbadge}.svg" 
  alt="LiveSession | Session replay software" /></a>`}
                </Code>
                <Preview>
                  <p>Preview: </p>
                  <img
                    src={currentbadge === 'light' ? badgeLight : badgeDark}
                    alt="Badge"
                    title="LiveSession Badge"
                  />
                </Preview>
              </li>
              <li>
                <p>
                  Let us know that you&apos;ve added the badge. Write to us at{' '}
                  <Link href="mailto:hello@livesession.io">
                    <strong>hello@livesession.io</strong>
                  </Link>{' '}
                  and we&apos;ll give you <strong>5,000 sessions</strong> for free.
                </p>
              </li>
            </ol>
          </div>
        </Section>
      </div>
    </Layout>
  );
};

export default Badge;
